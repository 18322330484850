import React from 'react';

import Layout from '../components/Layout';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';
import { graphql } from 'gatsby';
import { Gallery } from '../components/Gallery';

const IndexPage = ({data}) => {
  return (<Layout fullMenu subPage>
      <Seo
        description=""
        keywords=""
        title="Portréty"
      />
      <article id="main">
        <header id='family-photo' className='sub-page'>
          <StaticImage
            src='../assets/images/pic03.jpeg' alt='Focení portrétů'
            placeholder='blurred'
            objectFit='cover'
            className='static-image'
            objectPosition='0% 0%'
          />
          <div className='header-text big'>
            <h1>Portréty</h1>
            <p>Jste jedineční a originální!</p>
          </div>
        </header>
        <section className="wrapper style5">
          <div className="inner">
            <h3>Jste jedineční a originální, a to je právě ta esence, kterou by měl správný portrét obsahovat.</h3>
            <p>
              Individuální a osobní přistup považuji za základ pro vznik portrétní fotogrfie, která vystihne jedinečnost fotografované osoby.
              Portrét lze fotit v exteriéru celoročně. Krása přírody nádherně doladí atmosféru výsledné fotografie.
            </p>

            <p>
              Jedním z nejdůležítějších okamžíků v životě ženy je období mateřství.
              Těhotenský portrét dokáže zachytit jedinečnost krásy ženy a uchovat okamžiky štěstí a očekávání příchodu nového života.
            </p>

            <hr />

            <div>
              <Gallery data={data} />
            </div>

            <hr />
            <h4>Kdy se nechat fotit v těhotenství? </h4>
            <p>
              Ideální je nechat na fotografii vyniknout krásu oblých tvarů kulatého břiška.
              Každě těhotenství je individální, ale obecně tato doba nastává okolo 30. týdne těhotenství.
              Focení je ideální v přítomnosti budoucího tatínka či sourozenců.
            </p>

          </div>
        </section>
      </article>
    </Layout>
  );
}

export const pageQuery = graphql`
  query PortraitsImages {
    allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: "gallery/portraits"}}
        sort: {order: DESC, fields: name}
    ) {
      edges {
        node {
          childImageSharp {
            thumb: gatsbyImageData(
              width: 276
              placeholder: BLURRED
            )
            full: gatsbyImageData(layout: FULL_WIDTH)
          }
            name
        }
      }
    }
  }
`

export default IndexPage;
